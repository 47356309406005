import React, { useState } from "react";
import { Link } from "react-router-dom";
import { wildroselogo1, wildroselogo2 } from "../assets";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-[#005da4] shadow-gray-400 shadow-md  sticky top-0 z-50">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto ">
        <Link to="/" className="flex items-center">
          <img src={wildroselogo2} className="h-24 mr-3" alt="Wildrose Logo" />
        </Link>
        <button
          onClick={toggleMobileMenu}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-expanded={isMobileMenuOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        <div
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } items-center justify-between w-full md:flex md:w-auto md:order-1`}
        >
          <ul className="flex flex-col  font-medium p-4 md:p-0 rounded-lg bg-[#005da4] md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-[#005da4] dark:bg-gray-800 md:dark:bg-gray-900] ">
            <li className="">
              <Link
                to="/"
                className="block nav-font py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 text-xl hover:no-underline hover:scale-110"
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/jobs"
                className="block  nav-font py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 text-xl hover:no-underline hover:scale-110"
              >
                Jobs
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="block nav-font py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 text-xl hover:no-underline hover:scale-110"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/employers"
                className="block nav-font py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 text-xl hover:no-underline hover:scale-110"
              >
                Employers
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block nav-font py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 text-xl hover:no-underline hover:scale-110"
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                className=" block py-2 pl-5 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 text-xl hover:no-underline hover:scale-110"
                to="/login"
              >
                <div className="flex text-white mb-0  nav-font">
                  Login | Register
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
