import React from "react";
import { Header, Footer } from "../components";
import officeVideo from "../public/office.mp4";
import { pie, pie2, roots, vision } from "../assets/index";
import Team from "../components/Team";
import WhyChoseUs from "../components/WhyChoseUs";
import { wildroselogo1 } from "../assets/index";
import Employerfaq from "../components/Employerfaq";
import corporate from "../assets/videos/corporate.mp4";

const mainVid =
  "https://wildrose-bucket.s3.us-east-2.amazonaws.com/corporate.mp4";

function Employers() {
  return (
    <div>
      <Header />
      <div className=" container-fluid " style={{ minHeight: "800px" }}>
        <section className="relative bg-center bg-no-repeat bg-white bg-blend-multiply">
          {/* Video container */}
          <div className="absolute inset-0 overflow-hidden -mb-20">
            <video
              style={{ objectFit: "contain" }}
              className="w-full"
              autoPlay
              loop
              muted
            >
              <source src={mainVid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          {/* Content */}
          <div className="relative px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
            <span>
              <h1 className="mb-4 valuebody  opacity-80 text-4xl font-extrabold tracking-tight leading-none text-[#005da4] md:text-5xl lg:text-6xl p-4 rounded-md">
                Invest in the best talent with <br />
                Wildrose Placement Inc
              </h1>
            </span>
            <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48"></p>
            <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
              <a
                href="/login"
                className="no-underline hover:no-underline hover:scale-105 inline-flex justify-center items-center py-3 px-5 text-2xl font-medium text-center text-white rounded-lg bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
              >
                Login
                <svg
                  className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
              <a
                href="/signup"
                className="inline-flex hover:scale-105 text-white hover:no-underline justify-center text-2xl items-center py-3 px-5 sm:ms-4 bg-gray-400 hover:bg-gray-600 hover:text-white font-medium text-center  rounded-lg border  focus:ring-4 focus:ring-red-400"
              >
                Sign Up
              </a>
            </div>
          </div>
        </section>
        <WhyChoseUs />
        <div className="container  py-5">
          <div className="row align-items-center mb-32">
            <div className="col-lg-6 px-3 mx-auto">
              <img
                src={pie}
                alt=""
                className="rounded-xl img-fluid mb-4 mb-lg-0"
              />
            </div>
            <div className="col-lg-6 order-2 order-lg-1">
              <i className="fa fa-bar-chart fa-2x mb-3 text-primary" />
              <h2 className="font-weight-light rootsec">Pool of Candidates:</h2>

              <p
                style={{ fontSize: "20px" }}
                className="text-3X1 text-justify text-muted mb-4 p-8"
              >
                We know most professions are regulated so we try to get the
                numbers of those NOC on demand professionals register and get
                assessed with different provinces. This reduces wait time for
                our clients to get the talent their are looking for. For
                instance for an electrician to go through Alberta apprenticeship
                board to be assessed it’s taking close to 5 months. But if we
                have an electrician who has been assessed the time is reduced
                significantly for our client to start the LMIA process. As you
                can see on the pie charts what so of candidates we have now and
                the stages we are at.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <i className="fa fa-leaf fa-2x mb-3 text-primary" />
              <h2 className="font-weight-light rootsec">LMIA:</h2>
              <p
                style={{ fontSize: "20px" }}
                className="text-muted mb-4 p-8 text-justify"
              >
                We also track our candidates LMIA progress whether it was done
                by authorized partner or not to make sure the time they launch
                their work permit application they ll be within the
                stipulated time.
              </p>
            </div>
            <div className="col-lg-6 px-3 mx-auto">
              <img src={pie2} alt="" className="img-fluid rounded-xl mb-lg-0" />
            </div>
          </div>
        </div>
        <Employerfaq />
      </div>
      <Footer />
    </div>
  );
}

export default Employers;
