import React from "react";
import {
  engineering,
  blueLogo,
  whiteLogo,
  wildroselogo1,
  wildroselogo2,
} from "../assets";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <>
      <div className="relative mt-10 bg-[#19294d] border-gray-[#1e3057] dark:bg-gray-[#1e3057] ">
        <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 chipo">
          <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
            <div className="md:max-w-md lg:col-span-2">
              <div className="">
                <a
                  href="/"
                  aria-label="Go home"
                  title="Wildrose Placement Inc."
                  className="inline-flex items-left"
                >
                  <img
                    className=""
                    style={{ width: "100%" }}
                    src={wildroselogo2}
                    alt="Wildrose Placement Inc. Logo"
                  />
                </a>
              </div>

              <div className="mt-4 lg:max-w-sm">
                <h1 className="mb-8 text-left text-md text-white">
                  Find us on:
                </h1>
                <div class="buttons-container">
                  <div class="button">
                    <Link
                      to="https://www.facebook.com/people/Wildrose-Placement-INC/61552947688017/?mibextid=eHce3h"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#316FF6" }}
                    >
                      <i class="mr-3 fab fa-facebook-f fa-2x hover:no-underline hover:scale-110"></i>
                    </Link>

                    <Link
                      to="https://twitter.com/WildroseINC01"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#316FF6" }}
                    >
                      <i class="mr-3 fa-brands fa-x-twitter fa-2x hover:no-underline hover:scale-110"></i>
                    </Link>

                    <Link
                      to="https://instagram.com/wildroseplacementinc?utm_source=qr"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#FD1D1D" }}
                    >
                      <i class="mr-3 fab fa-instagram fa-2x hover:no-underline hover:scale-110"></i>
                    </Link>

                    <Link
                      to="https://youtube.com/@WildrosePlacementINC.?si=P0r3eFoFp684BJaV"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#FF0000" }}
                    >
                      <i class="mr-3 fab fa-youtube fa-2x hover:no-underline hover:scale-110"></i>
                    </Link>

                    <Link
                      to="https://t.me/+G0qj66r9suFiZjIx"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#0088cc" }}
                    >
                      <i class="mr-3 fab fa-telegram fa-2x hover:no-underline hover:scale-110"></i>
                    </Link>

                    <Link
                      to="https://web.whatsapp.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#25D366" }}
                    >
                      <i class="mr-3 fab fa-whatsapp fa-2x hover:no-underline hover:scale-110"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
              <div>
                <p className="text-left text-3xl font-semibold tracking-wide text-teal-accent-400"></p>

                <ul className="mt-2 space-y-2 text-left">
                  <li>
                    <a
                      href="/"
                      className="text-white text-left transition-colors duration-300  text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="employers"
                      className=" text-white text-left transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Employers
                    </a>
                  </li>
                  <li>
                    <a
                      href="/jobs"
                      className="text-white text-left transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Jobs
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about"
                      className="text-white text-left transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      className="text-white text-left transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="/faqs"
                      className="text-white text-left transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      FAQs
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="text-left text-3xl font-semibold tracking-wide text-teal-accent-400"></p>
                <ul className="mt-2 space-y-2 text-left">
                  <li>
                    <a
                      href="/about#about"
                      className="text-white text-left transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="/about#team"
                      className="text-white text-left transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      className="text-white text-left transition-colors duration-300 text-deep-purple-50 hover:no-underline hover:scale-110"
                    >
                      <FontAwesomeIcon
                        className="pr-3"
                        icon={faPhone}
                        style={{ fontSize: "24px" }}
                      />
                      +15873982542
                    </a>
                  </li>
                  <li>
                    <a
                      href="/privacy"
                      className="text-white text-left transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/terms"
                      className="text-white text-left transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Terms and Conditions
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <iframe
                  title="Google Maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1917.723648766867!2d-110.71648080684378!3d54.264575014382984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a7a82f5f653587%3A0x47741029d6ba7848!2s4502%2038%20St%2C%20Bonnyville%2C%20AB%20T9N%200A8!5e1!3m2!1sen!2sca!4v1703333626970!5m2!1sen!2sca"
                  width=""
                  height="250"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between pt-5 pb-10 border-t border-deep-purple-accent-200 sm:flex-row">
            <p className="text-sm text-gray-100">
              © Copyright {currentYear} Wildrose Placement Inc. All rights
              reserved.
            </p>
            <p className="text-xs">Powered by ©RufusTech</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
