import React from "react";
import { hired, wehire, whiteLogo } from "../assets";
import { Link, useNavigate } from "react-router-dom";
import SearchBox from "./SearchBox";

const Hero = () => {
  const nav = useNavigate();

  const handleSearch = (searchQuery) => {
    // Redirect to the /jobs page with the search query
    nav(`/jobs?query=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <section className="bg-white dark:bg-white">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-2 lg:grid-cols-12 ">
        <div className="mr-auto place-self-center lg:col-span-7">
          <div className="max-w-2xl mb-2 text-xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white text-[#c02128]">
            <img src={whiteLogo} alt="mockup" />
          </div>
          <p className="max-w-2xl mx-10 mb-6 font-light text-[#005da4] lg:mb-8 md:text-lg lg:text-xl dark:text-[#423A3A] text-left">
            Wildrose: Your Gateway to Professional Success and Endless Career
            Possibilities.
          </p>

          <div className="row-start-1 row-end-4 mx-1">
            <form>
              <div>
                <label
                  htmlFor="default-search"
                  className="mb-0 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search Items
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>
                  <SearchBox onSearch={handleSearch} />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="grid-flow-col hidden gap-4 lg:mt-8 lg:col-span-5 lg:flex ml-36 animate-bounce h-28">
          <Link
            to="/jobs"
            className="text-xl h-64 w-64 bg-gradient-to-r mr-auto from-[#005da4] to-[#e62328] rounded-full inline-flex items-center bg-[#c02128] justify-center px-2 py-3 ml-28 mt-8 text-base font-medium text-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:no-underline duration-300 text-white focus:ring-4 focus:ring-[#e62328] dark:focus:ring-[#e62328]"
          >
            Get started Now
            <svg
              className="w-5 h-5 ml-2 -mr-1 -mt-2"
              fill="currentColor"
              viewBox="0 0 18 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div class="mb-8 max-w-xl bg-white  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div
                style={{}}
                class="flex flex-col items-center bg-white border border-red-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
              >
                <img
                  style={{ height: "18.5rem" }}
                  class="object-cover w-full  rounded-t-lg md:h-auto md:w-60 md:rounded-none md:rounded-s-lg"
                  src={wehire}
                  alt=""
                />
                <div class=" flex flex-col justify-between leading-normal">
                  <span className="text-[#005da4] valuebody">
                    <h1 class="valueH1">Employers</h1>{" "}
                  </span>
                  {/* <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">Noteworthy technology acquisitions 2021</h5> */}
                  <p class="p-3 text-justify mb-1 font-medium text-gray-700 dark:text-gray-900">
                    Experience effortless hiring and empower your team with
                    top-tier talent from Wildrose Placement Inc. Explore our
                    placement solutions
                  </p>

                  <a className="pb-4" href="/employers">
                    <button
                      style={{ backgroundColor: "#005da4" }}
                      type="button"
                      class="py-3 px-5  me-2 mb-0 text-xl font-large  rounded-full shadow-red-300 shadow-md text-white focus:z-10 focus:ring-4 focus:ring-gray-200 hover:scale-110"
                    >
                      Learn More
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="max-w-xl bg-white  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                <img
                  style={{ height: "18.5rem" }}
                  class="object-cover w-full rounded-t-lg  md:h-auto md:w-60
                   md:rounded-none md:rounded-s-lg"
                  src={hired}
                  alt=""
                />
                <div class="flex flex-col justify-between leading-normal">
                  <span className="text-[#005da4] valuebody">
                    {" "}
                    <h1>Job Seekers</h1>
                  </span>
                  {/* <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">Noteworthy technology acquisitions 2021</h5> */}
                  <p class="p-3 text-justify mb-1 font-medium text-gray-700 dark:text-gray-900">
                    Experience effortless hiring and empower your team with
                    top-tier talent from Wildrose Placement Inc. Explore our
                    placement solutions
                  </p>
                  <a className="pb-4" href="/jobs">
                    <button
                      style={{ backgroundColor: "#005da4" }}
                      type="button"
                      class="py-3 px-5 me-2 mb-0 text-xl font-large  rounded-full shadow-red-300 shadow-md text-white focus:z-10 focus:ring-4 focus:ring-gray-200 hover:scale-110"
                    >
                      Learn More
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container fade-in-and-settle-hr"></div>
    </section>
  );
};

export default Hero;
